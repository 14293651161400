import { lyraClient } from '../constants/client'

export async function estimateBlockNumberAtDate(
  targetDate: Date,
  startBlock?: number
): Promise<number> {
  // Convert the Date object to a Unix timestamp (in seconds)
  const targetTimestamp = Math.floor(targetDate.getTime() / 1000)

  // Step 1: Get the current block number and its timestamp
  const latestBlock = await lyraClient.getBlock({ blockTag: 'latest' })
  const earliestBlockNumber = BigInt(startBlock !== undefined ? startBlock : 0)
  const earliestBlock = await lyraClient.getBlock({ blockNumber: earliestBlockNumber })

  // Binary search initialization
  let low = Number(earliestBlock.number)
  let high = Number(latestBlock.number)

  while (low <= high) {
    const mid = Math.floor((low + high) / 2)
    const midBlock = await lyraClient.getBlock({ blockNumber: BigInt(mid) })

    if (Number(midBlock.timestamp) === targetTimestamp) {
      return Number(midBlock.number) // Exact match
    } else if (midBlock.timestamp < targetTimestamp) {
      low = mid + 1 // Target is in the upper half
    } else {
      high = mid - 1 // Target is in the lower half
    }
  }

  // If exact timestamp is not found, return the closest block number
  const closestBlock = await lyraClient.getBlock({ blockNumber: BigInt(high) })
  return Number(closestBlock.number)
}
