import { BatchUserOperationCallData } from '@alchemy/aa-core'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import { Address } from 'viem'

import rewardsDistributorAbi from '../abis/rewardsDistributorAbi'
import { lyraClient } from '../constants/client'
import { lyraContractAddresses } from '../constants/contracts'
import { ClaimableRewards, REWARDS_ROUND_CONFIGS, RewardsCategory } from '../constants/rewards'
import { baseUrl } from '../constants/urls'
import { getDistributorClaimTx } from './drv'
import { getLyraTokenForAddress, getTokenDecimals } from './tokens'

export const getRewardsRoundForTimestamp = (now: Date) => {
  return (
    REWARDS_ROUND_CONFIGS.find(
      (epoch) => epoch.endTimestamp >= now.getTime() && epoch.startTimestamp <= now.getTime()
    ) ?? REWARDS_ROUND_CONFIGS[REWARDS_ROUND_CONFIGS.length - 1] // get latest
  )
}

export const getRewardsRounds = (now = new Date()) => {
  return REWARDS_ROUND_CONFIGS.filter((round) => round.startTimestamp <= now.getTime())
}

export const getPrevRewardsRoundForTimestamp = (now: Date) => {
  const currRound = getRewardsRoundForTimestamp(now)
  const prevRoundId = Math.max(currRound.roundId - 1, 1)
  const prevRound = getRewardsRound(prevRoundId)
  return prevRound
}

export const getRewardsRound = (targetRoundId: number) => {
  let left = 0,
    right = REWARDS_ROUND_CONFIGS[REWARDS_ROUND_CONFIGS.length - 1].roundId
  let closestGreater = REWARDS_ROUND_CONFIGS[right]

  while (left <= right) {
    const mid = Math.floor((left + right) / 2)

    if (REWARDS_ROUND_CONFIGS[mid].roundId === targetRoundId) {
      return REWARDS_ROUND_CONFIGS[mid]
    } else if (REWARDS_ROUND_CONFIGS[mid].roundId > targetRoundId) {
      closestGreater = REWARDS_ROUND_CONFIGS[mid]
      right = mid - 1 // Search in the left half
    } else {
      left = mid + 1 // Search in the right half
    }
  }

  return closestGreater
}

export const getReferralLinks = (
  referralCode: string
): {
  inviteLink: string
  twitterShareLink: string
} => {
  const inviteLink = `${baseUrl}/invite/${referralCode}`
  return {
    inviteLink,
    twitterShareLink: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `Earn DRV, OP and USDC when you trade on Derive: ${inviteLink}`
    )}`,
  }
}

export const getRewardsCategoryForBatchTag = (tag: string): RewardsCategory | null => {
  if (tag.includes('trading')) {
    return 'trading'
  } else if (tag.includes('referral')) {
    return 'referral'
  } else if (tag.includes('staking')) {
    return 'staking'
  } else if (tag.includes('aero')) {
    return 'aero'
  } else if (tag.includes('airdrop')) {
    return 'airdrop'
  } else {
    return null
  }
}

export const fetchClaimableRewards = async (
  address: Address,
  selectedCategory?: RewardsCategory,
  ignoreTags?: string[]
): Promise<ClaimableRewards> => {
  const allBatches = await lyraClient.readContract({
    abi: rewardsDistributorAbi,
    address: lyraContractAddresses.rewardsDistributor,
    functionName: 'getAllUserBatchInfo',
    args: [address],
  })

  const claimableRewardBatches = allBatches.filter((batch) => batch.enabled)

  const claimableRewards: ClaimableRewards = {
    trading: {},
    referral: {},
    staking: {},
    aero: {},
    airdrop: {},
  }

  claimableRewardBatches.forEach((batch) => {
    const token = getLyraTokenForAddress(batch.token)
    const tag = batch.tag
    const category = getRewardsCategoryForBatchTag(tag)
    if (!category) {
      console.warn(`skipped claimable reward with unrecognized tag ${tag}`)
      return
    }

    if (selectedCategory && category !== selectedCategory) {
      return
    }

    if (ignoreTags?.includes(tag)) {
      return
    }

    if (!claimableRewards[category][token]) {
      // initialize
      claimableRewards[category][token] = {
        amount: 0,
        batchIds: [],
        batchTags: [],
      }
    }

    const amountNum = bigNumberToNumberUNSAFE(batch.amount, getTokenDecimals(token))
    claimableRewards[category][token].amount += amountNum
    claimableRewards[category][token].batchIds.push(batch.batchId.toString())
    claimableRewards[category][token].batchTags.push(batch.tag.toString())
  })

  return claimableRewards
}

export const getClaimRewardsTxs = async (
  batchIds: string[]
): Promise<BatchUserOperationCallData> => {
  return batchIds.map((batchId) => getDistributorClaimTx(BigInt(batchId)))
}
